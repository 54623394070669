<template>
  <div style="max-width: 400px">
    <div class="neo-card">
      <h2 class="heading-size-4 mb-4">{{ $t("verifyEmailMsg") }}</h2>
      <div class="neo-text-field">
        <div>{{ $t("email") }}</div>
      </div>
      <v-text-field
        ref="email"
        v-model="email"
        label="email"
        :rules="[rules.required, rules.email, rules.max]"
        :disabled="emailAvailable"
      ></v-text-field>
      
      <div>
        {{
          $store.state.user.emailVerified
            ? this.$t("emailVerified")
            : this.userMessage
        }}
      </div>
      <v-btn
        v-if="!this.$store.state.user.emailVerified"
        ref="getBtn"
        id="get-code-button"
        class="mt-4"
        block
        :disabled="
          waiting || !rules.emailValid(email) || !rules.belowMax(email)
        "
        color="primary"
        @click="resendEmail"
      >
        {{ `${$t("resendEmail")}${countdown > 0 ? " " + countdown : ""}` }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { firebase } from "@/helpers/firebase";

export default {
  name: "VerifyEmail",
  props: {
    profileEmail: String,
  },
  data() {
    return {
      userMessage: " ",
      interval: 0,
      countdownInterval: 0,
      countdown: 0,
      email: "",
      telephoneInputS: "",
      telephoneInput: {
        number: "",
        valid: false,
        country: "JP",
      },
      validPhone: false,
      phoneError: false,
      manualRecaptcha: undefined,
      waiting: false,
      recaptchaOk: false,
      valid: true,
      rules: {
        max: (v) => (v && v.length <= 32) || "Max 32 characters",
        belowMax: (v) => v && v.length <= 32,
        error: () => true || "",
        required: (value) => !!value || "Required.",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        emailValid: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ),
      },

      disableGetCode: false,
      confirmationResult: undefined,
    };
  },
  computed: {
    emailAvailable() {
      return (
        this.rules.emailValid(this.email) &&
        this.email === this.$store.state.user.email
      );
    },
  },
  watch: {
    profileEmail(val) {
      if (val) {
        this.email = val;
        this.userMessage = this.$t("noEmailReceived");
        this.checkEmailPeriodically();
      }
    },
  },
  methods: {
    async resendEmail() {
      this.waiting = true;
      this.countdown = 60;
      clearInterval(this.countdownInterval);
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown === 15)
          this.userMessage = this.$t("noEmailReceived");
        if (this.countdown === 1) {
          this.countdown = 0;
          clearInterval(this.countdownInterval);
          this.waiting = false;
        }
      }, 1000);

      firebase.auth().languageCode = this.$store.state.lang;
      try {
        await this.$store.state.user.updateEmail(this.email);
        this.$store.state.user
          .sendEmailVerification()
          .then(() => {
            this.userMessage = this.$t("verificationEmailSent");
            this.checkEmailPeriodically();
          })
          .catch(function (error) {
            // Error occurred. Inspect error.code.
            this.userMessage = error.message;
          }.bind(this));
      } catch (error) {
        this.userMessage = `error ${this.$store.state.user.uid}`;
      }
    },
    clearError() {
      this.errorMsgPwd = "";
      this.errorMsgEmail = "";
      this.rules.error = true;
    },
    handleEnter() {
      this.$refs.sendBtn.$el.click();
    },
    checkEmailVerified() {
      this.$store.state.user.reload().then(async () => {
        let currentUser = await firebase.auth().currentUser;
        if (currentUser) {
          await currentUser.reload();
          currentUser = await firebase.auth().currentUser;
          if (currentUser.emailVerified) {
            this.userMessage = this.$t("emailVerified");
            this.$store.commit("updateUser", currentUser);
            clearInterval(this.interval);
            this.$emit("emailVerified");
          }
        }
      });
    },
    checkEmailPeriodically() {
      this.checkEmailVerified();
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.checkEmailVerified();
      }, 3000);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.countdownInterval);
  },
  beforeMount() {
    this.email = this.$store.state.user.email;
    
    if (!this.email) this.userMessage = this.$t("promptEmail");
    else {
      this.userMessage = this.$t("noEmailReceived");
      this.checkEmailPeriodically();
    }
  },
};
</script>
